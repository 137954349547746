export const theme = {
  primaryColor: "#213458",
  muted: "#6a6a6a",
  secondaryColor: "#1D7D81",
  secondaryColor30: "#1D7D814D",
  lines: "#DDE2E2",
  background: "#F0F0F0",
  accentColor: "#DDDDDD",
  errorColor: "#f07f5e",
  success: "#4BB543",
  info: "#b8cef9",
};
