import React from "react";
import { Loader } from "@aws-amplify/ui-react";
import { theme } from "../../../theme";

export default () => (
  <Loader
    style={{ marginRight: 5, marginLeft: 5, top: 10 }}
    size="large"
    emptyColor="#DDDDDD"
    filledColor={theme.primaryColor}
  />
);
