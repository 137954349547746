import { AmplifyProvider } from "@aws-amplify/ui-react";
import { lazy, Suspense } from "react";
import LoaderBox from "./domains/shared/components/LoaderBox";

import "./App.scss";

// This will split the app into required boilerplate chunk and "business" chunks, each about 250kb.
const Router = lazy(() => import("./Router"));

export default () => (
  <AmplifyProvider>
    <Suspense fallback={<LoaderBox />}>
      <Router />
    </Suspense>
  </AmplifyProvider>
);
